import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MessageService } from './message.service';
import { NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private tokenTemp: string;
  stateLog = new EventEmitter<boolean>();
  stateAdm = new EventEmitter<boolean>();

  constructor(private auth: AngularFireAuth,
              private router: Router,
              private msg: MessageService) { }

  loginAccount(f: NgForm) {
    if (f.invalid) {return; }
    this.msg.messageInfo('Iniciando sesión...', '');
    Swal.showLoading();
    this.auth.auth.signInWithEmailAndPassword(f.value.email, f.value.pass)
      .then( cred => {
        cred.user.getIdToken(true).then(token => {
          this.guardToken(token);
          cred.user.getIdTokenResult().then(idtoken => {
            if (!!idtoken.claims.admin) {
              this.stateAdm.emit( true );
              Swal.close();
              this.msg.messageCorrect('Bienvenido administrador!', 'A continuación te redirigiremos a tu panel.');
              localStorage.setItem('status', 'true');
              this.router.navigateByUrl('/management');
            } else {
              if(!!idtoken.claims.partner) {
                this.stateLog.emit( true );
                Swal.close();
                this.msg.messageCorrect('Bienvenido', 'A continuación te redirigiremos a tu perfil.');
                localStorage.setItem('email', f.value.email);
                this.router.navigateByUrl('/profile');
              }
            }
          })
      }).catch(() => {
          Swal.close();
          this.msg.messageError('Error en la autenticación de socio', 'Su cuenta no esta disponible en estos momentos');
        });
      }).catch(() => {
        Swal.close();
        this.msg.messageError('Autenticación fallida', 'Su correo o contraseña es incorrecta');
      })
  }

  private guardToken(idToken: string) {
    localStorage.setItem('token', idToken);
    this.tokenTemp = idToken;
    let hoy = new Date();
    hoy.setSeconds(3600);

    localStorage.setItem('expired', hoy.getTime().toString());
  }

  isAuth(): boolean {
    if (this.tokenTemp !== undefined && this.tokenTemp.length < 2) {
      return false;
    } else {
      const expired = Number(localStorage.getItem('expired'));
      const expiredDate = new Date();
      expiredDate.setTime(expired);

      if ( expiredDate > new Date() ) {
        return true;
      } else {
        return false;
      }
    }
  }

  statusLog(): boolean {
    return this.statusLogin();
  }

  private statusLogin(): boolean {
    return ( localStorage.getItem('token') ) ? false : true;
  }

  statusAd(): boolean {
    return this.statusAdmin();
  }

  private statusAdmin(): boolean {
    return ( localStorage.getItem('status') ) ? false : true;
  }

  logout() {
    this.auth.auth.signOut();
    this.resetLocalStorage();
    location.reload();
    this.router.navigateByUrl('/home');
  }

  private resetLocalStorage() {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('expired');
      localStorage.removeItem('email');
      localStorage.removeItem('status');
    }
  }
}
