import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { RegisterService } from '../../services/register.service';
import { Partner } from '../../models/usuario.models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  formRegister: FormGroup;
  usuario: Partner;
  check = false;
  statusCheckCode = false;
  namePage = 'Registro';
  codeCheck = '';

  constructor(private formBuilder: FormBuilder,
              private registerService: RegisterService) {
                this.createForm();
              }

  ngOnInit() {
  }

  get codeNoValid() {
    return this.formRegister.get('code').invalid && this.formRegister.get('code').touched;
  }

  get nameNoValid() {
    return this.formRegister.get('name').invalid && this.formRegister.get('name').touched;
  }

  get subnameNoValid() {
    return this.formRegister.get('subname').invalid && this.formRegister.get('subname').touched;
  }

  get subname2NoValid() {
    return this.formRegister.get('subname2').invalid && this.formRegister.get('subname2').touched;
  }

  get emailNoValid() {
    return this.formRegister.get('email').invalid && this.formRegister.get('email').touched;
  }

  get rutNoValid() {
    return this.formRegister.get('rut').invalid && this.formRegister.get('rut').touched;
  }

  get adressNoValid() {
    return this.formRegister.get('adress').invalid && this.formRegister.get('adress').touched;
  }

  get phoneNoValid() {
    return this.formRegister.get('phone').invalid && this.formRegister.get('phone').touched;
  }

  get pass1NoValid() {
    return this.formRegister.get('pass').invalid && this.formRegister.get('pass').touched;
  }

  get pass2NoValid() {
    return this.formRegister.get('pass2').invalid && this.formRegister.get('pass2').touched;
  }

  createForm() {
    this.formRegister = this.formBuilder.group({
      code: ['', Validators.compose([
        Validators.required, Validators.minLength(10), Validators.maxLength(15)
      ])],
      name: ['', Validators.required],
      subname: ['', Validators.required],
      subname2: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
      ])],
      rut: ['', Validators.required],
      adress: ['', Validators.required],
      phone: ['', Validators.required],
      pass: ['', Validators.compose([
        Validators.required, Validators.minLength(8)
      ])],
      pass2: ['', Validators.compose([
        Validators.required, Validators.minLength(8)
      ])],
      chkbox: ['', Validators.requiredTrue]
    }, {
      validators: this.registerService.passwordsEquals('pass', 'pass2')
    });
  }

  checkingCode() {
    const codeTemp = this.formRegister.controls.code.value;
    let resultCode = this.registerService.checkCode(codeTemp);
    if ( resultCode == true ) {
      this.check = true;
      this.statusCheckCode = false;
      this.codeCheck = codeTemp;
      return this.check;
    } else {
      this.statusCheckCode = true;
      this.codeCheck = 'CÓDIGO INVALIDO';
      return this.check;
    }
  }

  onSubmit(forma: NgForm) {
    if (this.formRegister.invalid ) {
       return Object.values( this.formRegister.controls ).forEach( ctrl => {
        ctrl.markAllAsTouched();
      });
    } else {
      this.registerService.registerAccount(forma);
    }

  }

}
