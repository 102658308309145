import { Component } from '@angular/core';
import { NgwWowService } from 'ngx-wow';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cannabis Crea';
  
  constructor(private _wowService: NgwWowService) {
  }

  ngOnInit(): void {
    this._wowService.init();    
  }
}
