import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { LegalComponent } from './components/legal/legal.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileUserComponent } from './pages/profile-user/profile-user.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';


import { AuthPartnerGuard } from './guards/auth-partner.guard';
import { AuthAdminGuard } from './guards/auth-admin.guard';


const routes: Routes = [
    { path: 'home', component: HomeComponent},
    { path: 'about', component: AboutComponent},
    { path: 'contact', component: ContactComponent},
    { path: 'legal', component: LegalComponent},
    { path: 'register', component: RegisterComponent},
    { path: 'login', component: LoginComponent},
    { path: 'profile', component: ProfileUserComponent, canActivate: [ AuthPartnerGuard ]},
    // tslint:disable-next-line: max-line-length
    { path: 'management', component: ProfilesComponent, canActivate: [ AuthAdminGuard ]},
    { path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
