import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formLogin: FormGroup;
  namePage = 'Inicio de Sesión';

  constructor(private fb: FormBuilder,
              private loginService: LoginService) {
    this.crearFormularioLogin();
  }

  ngOnInit() {
  }

  get emailNoValid() {
    return this.formLogin.get('email').invalid && this.formLogin.get('email').touched;
  }

  get passNoValid() {
    return this.formLogin.get('pass').invalid && this.formLogin.get('pass').touched;
  }

  crearFormularioLogin() {
    this.formLogin = this.fb.group({
      email: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
      ])],
      pass: ['', Validators.compose([
        Validators.required, Validators.minLength(8)
      ])]
    });
  }

  login(form: NgForm) {
    if (this.formLogin.invalid ) {
       return Object.values( this.formLogin.controls ).forEach( ctrl => {
        ctrl.markAllAsTouched();
      });
    } else {
      this.loginService.loginAccount(form);
    }
  }

}
