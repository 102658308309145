import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { environment } from '../environments/environment';
import { NgwWowModule } from 'ngx-wow';
import { NgxCaptchaModule } from 'ngx-captcha';
// Routes
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { LegalComponent } from './components/legal/legal.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileUserComponent } from './pages/profile-user/profile-user.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { FirstComponent } from './components/first/first.component';
import { NgDropPrescriptionDirective } from './directives/ng-drop-prescription.directive';
import { AngularFireStorage } from '@angular/fire/storage';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    LegalComponent,
    RegisterComponent,
    LoginComponent,
    ProfileUserComponent,
    ProfilesComponent,
    FirstComponent,
    NgDropPrescriptionDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthGuardModule,
    NgwWowModule,
    NgxCaptchaModule
  ],
  providers: [
    AngularFireAuth,
    AngularFirestore,
    AngularFireStorage
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
