import { Injectable } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { Partner } from '../models/usuario.models';
import { MessageService } from './message.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private dbNameCode = 'codes';
  testingCodes: any[] = new Array<any>();
  usuario: Partner;
  recordToken = false;
  private dbUsers = 'users';

  constructor(private auth: AngularFireAuth,
              private db: AngularFirestore,
              private msg: MessageService,
              private cf: AngularFireFunctions,
              private router: Router) { 
                this.getCodes();
              }

  // check code without CF.
  checkCode(code: string): boolean {
    let resultCode = this.checkCodeWork(code);
    return resultCode;
  }

  private checkCodeWork(code: string):boolean {
    const dateToday = new Date();
    let i = this.testingCodes.length;
    let resultCode = false;
    while (i > 0){
      if(this.testingCodes[i-1].code === code && dateToday.getTime() < this.testingCodes[i-1].endDate.toDate().getTime() && this.testingCodes[i-1].status === "SIN USO") {
        resultCode = true;
        break;
      }
      i = i - 1;
    }
    return resultCode;
  }

  //Testing get code
  private getCodes() {
    this.db.collection(this.dbNameCode).get().subscribe(
      res => {
        res.docs.forEach(item => {
          this.testingCodes.push(item.data());
        })
      });
  }

  passwordsEquals(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      const pass1Control = formGroup.controls[pass1Name];
      const pass2Control = formGroup.controls[pass2Name];

      if (pass1Control.value === pass2Control.value) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ isntEqual: true });
      }
    };
  }

  registerAccount(f: NgForm) {
    if (f.invalid) {return; }
    this.msg.messageInfo('Espere por favor...', '');
    Swal.showLoading();
    this.auth.auth.createUserWithEmailAndPassword(f.value.email, f.value.pass).then( cred => {
      this.setValues(cred, f)
        .then(() => cred.user.getIdToken(true).then( token => {
          this.guardToken(token);
      }))
        .catch( () => {
          if (cred === null) {
            Swal.close();
            this.msg.messageError('Hubo un error con el registro.', 'Por favor, contacte con un administrador, o vuelva a intentarlo.');
          }
        });
    }).then( () => {
      Swal.close();
      this.msg.messageCorrect('Socio '+ f.value.name + ' registrado!', 'Usted ya está registrado, ahora debe esperar la aprobación de un administrador para activar la cuenta.');
    }).then(() => this.router.navigateByUrl('/home')).catch( err => {
      if (err.code === 'auth/email-already-in-use') {
        this.msg.messageError('Error al registrar', 'Correo en uso, si tiene problemas contacte con el administrador.');
      }
    }).catch( () => {
      this.msg.messageError('error al registrar', 'Hubo un error inesperado, por favor vuelva a intentarlo.');
    });
  }

  private async setValues(cred: firebase.auth.UserCredential, f: NgForm) {
    return await this.db.collection(this.dbUsers).doc(cred.user.uid).set({
      code: f.value.code,
      name: f.value.name,
      subname: f.value.subname,
      subname2: f.value.subname2,
      email: f.value.email,
      rut: f.value.rut,
      adress: f.value.adress,
      phone: f.value.phone,
      socio: 'Desactivado',
      role: 'USER_ROLE',
      pathCard: 'sin carnet',
      pathContract: 'sin contrato',
      terms: f.value.chkbox
    });
  }

  private guardToken(idToken: string) {
    localStorage.setItem('token', idToken);

    let hoy = new Date();
    hoy.setSeconds(3600);

    localStorage.setItem('expired', hoy.getTime().toString());
  }
}
