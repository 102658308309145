import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  statusLog = false;
  statusAdm = false;

  constructor(private ls: LoginService) {
    // this.statusLog = ls.statusLog();
    // this.statusAdm = ls.statusAd();
   }

  ngOnInit() {
    this.ls.stateLog.subscribe(status => {
      this.statusLog = status;
    });
    this.ls.stateAdm.subscribe(status => {
      this.statusAdm = status;
    });
  }

  logout() {
    if (this.statusAdm) {
      this.ls.logout();
      this.statusAdm = false;
    } else {
      if (this.statusLog) {
        this.ls.logout();
        this.statusLog = false;
      }
    }
  }
}
