import { Injectable } from '@angular/core';
import { CanActivate,  Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {

  constructor(private auth: AngularFireAuth,
              private router: Router) {}
  canActivate() {
    return this.auth.authState.pipe(
      take(1),
      switchMap(async (authState) => {
          if (authState) { // check are user is logged in
              const token = await authState.getIdTokenResult();
              if (!token.claims.admin) { // check claims
                  this.router.navigateByUrl('/home');
                  return false;
              } else {
                  return true;
              }
          } else {
              this.router.navigateByUrl('/home');
              return false;
          }
      })
  );


    // if (this.logged.isAuth() && this.logged.isAuthAdmin()) {
    //   return true;
    // } else {
    //   this.router.navigateByUrl('/home');
    //   return false;
    // }
  }
}
