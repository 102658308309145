import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { LoginService } from '../../services/login.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import 'firebase/firestore';
import { Partner } from '../../models/usuario.models';
import { MessageService } from '../../services/message.service';
import { Prescription } from '../../models/prescription.models';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  namePage = 'Panel de administrador';
  private dbUsers = 'users';
  userAdmin: Partner;
  code: string = null;
  emailUser: string;
  text: string;
  partners: Partner[] = new Array<Partner>();
  partnerSelect: Partner;
  // status activity
  selectDesignRole = false;
  selectCreateCode = false;
  selectEditPartner = false;
  selectCreateNew = false;
  //add role
  // partnersUserRole: Partner[] = new Array<Partner>();
  // date for code
  initDate: Date = null;
  endDate: Date = null;
  copied = false;
  detailsCreator = false;
  loadingCreateCode = false;

  @Output() selectedPartner = new EventEmitter();

  // edit partner in menu
  partnerSelectedEdit: Partner;
  isEdit = false;
  formPartnerEdit: FormGroup;

  // prescription variables
  isAddPrescription = true;
  isEditPrescription = false;
  isHistoryPrescription = false;

  //drop description
  private db_prescription = 'prescriptions';
  onDrop = false;
  prescription: Prescription[] = [];
  initPrescription: Date = null;
  endPrescription: Date = null;
  prescriptionsPartnerSelected: any[] = new Array<any>();
  //advanced options
  isVisibleMainMenu = false;
  isVisibleMenuProfile = false;
  isVisibleMenuPrescription = false;
  isVisibleAdvancedOption = false;

  //options prescription
  isVisibleMenuButtomDescription = false;
  isVisibleAddPrescription = false;
  isVisibleEditPrescription = false;

  constructor(private ls: LoginService,
              private auth: AngularFireAuth,
              private db: AngularFirestore,
              private storage: AngularFireStorage,
              private fb: FormBuilder,
              private msj: MessageService,
              private cf: AngularFireFunctions) {
  }

  ngOnInit() {
    this.ls.stateAdm.emit( true );
    this.getDataUser(this.getUserAdmin());
    // this.getPartnersWithUserRole();
    this.getPartners();
  }

  private getUserAdmin(): string {
    return this.auth.auth.currentUser.uid;
  }

  private getDataUser(uid: string) {
    this.db.collection(this.dbUsers).doc(uid).get().subscribe(data => {
      this.userAdmin = data.data() as Partner;
      this.userAdmin.id = data.id;
      this.userAdmin.ref = data.ref;
      this.userAdmin.visible = true;
    });
  }

  // ------------------------------ SELECT ACTIVITY ------------------------------- //
  // Choise activity
  selectActivity(t: number) {
    this.selActivity(t);
  }

  private selActivity(t: number) {
    switch (t) {
      case 1:
          this.selectDesignRole = true;
          this.selectCreateCode = false;
          this.selectEditPartner = false;
          this.selectCreateNew = false;
          break;
      case 2:
          this.selectDesignRole = false;
          this.selectCreateCode = true;
          this.selectEditPartner = false;
          this.selectCreateNew = false;
          break;
      case 3:
          this.selectDesignRole = false;
          this.selectCreateCode = false;
          this.selectEditPartner = true;
          this.selectCreateNew = false;
          this.isVisibleMainMenu = true;
          break;
      case 3:
          this.selectDesignRole = false;
          this.selectCreateCode = false;
          this.selectEditPartner = false;
          this.selectCreateNew = true;
          break;
      default:
          console.log('this option doesn\'t exists!');
    }
  }

  selectActivityProfile(x: number){
    this.selActivityProfile(x);
  }
  // Select menu profile partner
  private selActivityProfile(x: number){
    switch(x) {
      case 1:
            this.isVisibleMainMenu = false;
            this.isVisibleMenuProfile = false;
            this.isVisibleMenuPrescription = false;
            this.isVisibleAdvancedOption = false;
            this.isVisibleAddPrescription = false;
            this.isVisibleEditPrescription = false;
            this.isHistoryPrescription = false;
            break;
      case 2:
            this.isVisibleMenuProfile = true;
            this.isVisibleMenuPrescription = false;
            this.isVisibleAdvancedOption = false;
            break;
      case 3:
            this.isVisibleMenuProfile = false;
            this.isVisibleMenuPrescription = true;
            this.isVisibleAdvancedOption = false;
            this.isHistoryPrescription = true;
            this.readPrescriptionsPartnerSelected();
            break;
      case 4:
            this.isVisibleMenuProfile = false;
            this.isVisibleMenuPrescription = false;
            this.isVisibleAdvancedOption = true;
            break;
      case 5:
            this.isVisibleMainMenu = true;
            break;
      default:
            console.log('this option doesn\'t exists!');
    }
  }

  selectActivityPrescription(y: number) {
    this.selActivityPrescription(y);
  }
  //select menu prescription partner.
  private selActivityPrescription(y: number) {
    switch(y) {
      case 1: 
            this.isVisibleMenuPrescription = false;
            this.isVisibleAddPrescription = false;
            this.isVisibleEditPrescription = false;
            this.isHistoryPrescription = false;
            break;
      case 2: 
            this.isVisibleAddPrescription = true;
            this.isVisibleEditPrescription = false;
            break;
      case 3: 
            this.isVisibleAddPrescription = false;
            this.isVisibleEditPrescription = true;
            break;
      default:
            console.log('this option doesn\'t exists!');
    }
  }


  // ------------------------------ ADD ROLE ---------------------------- //

  changeRole(partner: Partner){
    this.updateRoleUserToPartner(partner);
    this.updateCodeUsed(partner);
    this.claimRoleUserToPartner(partner);
    this.getPartners();
    this.msgAddRole(partner);
  }

  private updateRoleUserToPartner(partner: Partner) {
    this.db.doc('users/'+partner.id).update({
      role: 'PARTNER_ROLE'
    });
  }

  private updateCodeUsed(partner: Partner) {
    this.db.doc('codes/'+partner.code).update({
      status: 'USADO'
    });
  }

  private claimRoleUserToPartner(partner: Partner) {
    const addClaimPartner = this.cf.httpsCallable('addPartnerRole');
    addClaimPartner({ email: partner.email}).toPromise().then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    });
  }

  msgAddRole(partner: Partner){
    this.msj.messageCorrect('Rol Activado!', 'El usuario '+ partner.name + ' ' + partner.subname + 'es ahora un Socio!');
    const emailSuccess = this.cf.httpsCallable('sendEmailPartnerSuccess');
    emailSuccess({ email: partner.email }).toPromise().then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  // ------------------------------ CODE ------------------------------- //
  // Generate code to partner
  private generateCode(): string {
    const chars = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'];
    const min = 10;
    const max = 15;
    const limit = Math.floor(Math.random() * (max - min + 1) + min);
    // tslint:disable-next-line: no-bitwise
    const randomCode = [...Array(limit)].map(i => chars[Math.random() * chars.length | 0]).join('');
    return randomCode;
  }

  genCode() {
    //Create a code with 3 attempts.
    this.createCode(3);
  }

  private updateCode() {
    const dbCodes = 'codes';
    const initDate = new Date();
    const endDate = new Date();
    endDate.setDate(initDate.getDate() + 3);
    this.initDate = initDate;
    this.endDate = endDate;
    this.db.collection(dbCodes).doc(this.code).set({
      mail: this.emailUser,
      code: this.code,
      initDate: this.initDate,
      endDate: this.endDate,
      createdBy: this.userAdmin.name + ' ' + this.userAdmin.subname,
      status: 'SIN USO'
    });
  }

  private getCodes(codes: string[]) {
    const dbCodes = 'codes';
    this.db.collection(dbCodes).get().subscribe(res => {
      res.docs.forEach(item => {
        codes.push(item.id);
      });
    });
  }

  private checkCode(code: string, codes: string[]): boolean{
    const codeCheck = code;
    const checkCodes = codes;
    checkCodes.forEach(item => {
      if (codeCheck === item) {
        return true;
      //     console.log('son iguales :o...' + codeCheck);
      }
    //     console.log(codeCheck + ' es distinto de ' + item);
    });
    return false;
  }

  private createCode(attempt: number) {
    if (attempt == 0){
      console.log("Por favor, cree nuevamente un código.");
    }
    this.code = this.generateCode();
    let codeTest = new Array<string>();
    this.getCodes(codeTest);
    // tslint:disable-next-line: triple-equals
    if (this.checkCode(this.code, codeTest) === true) {
      this.code = null;
      codeTest = [];
      this.createCode(attempt - 1);
    } else {
      this.updateCode();
      this.waitingCode();
    }
  }

  waitingCode() {
    this.copied = true;
    this.loadingCreateCode = true;
    setTimeout(() => {
      this.detailsCreator = true;
      this.loadingCreateCode = false;
    }, 3000 );
  }

  copyCode(val: string) {
    const selBox = document.createElement('textarea');
    selBox.id = 'txt';
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  // ------------------------------ MANAGE PARTNER ---------------------------- //
  // Manage partners
  private getPartners() {
    this.db.collection(this.dbUsers).get().subscribe(res => {
      this.partners.length = 0;
      res.docs.forEach(item => {
        if (item.id !== this.getUserAdmin()) {
          const partner: Partner = item.data() as Partner;
          partner.id = item.id;
          partner.ref = item.ref;
          partner.visible = true;
          this.partners.push(partner);
        }
      });
    });
  }

  searchPartner(text: string) {
    this.partners.forEach(partner => {
      const completeExpression = `${ partner.name } ${ partner.subname }`.toLowerCase();
      return (completeExpression.includes(text.toLowerCase())) ? partner.visible = true : partner.visible = false;
    });
  }

  selectPartner(partner: Partner) {
    this.text = `${ partner.name } ${ partner.subname }`;
    this.partners.forEach(res => {
      res.visible = false;
    });
    this.partnerSelect = partner;
    this.createFormEditPartner();
    this.selectedPartner.emit(partner);
    this.selectActivityProfile(5);
  }

  cancelPartner() {
    this.text = undefined;
    this.partners.forEach(res => {
      res.visible = true;
    });
    this.selectActivityProfile(1);
  }

  createFormEditPartner() {
    this.formPartnerEdit = this.fb.group({
      name: [this.partnerSelect.name, Validators.required],
      subname: [this.partnerSelect.subname, Validators.required],
      rut: [this.partnerSelect.rut, Validators.required],
      phone: [this.partnerSelect.phone, Validators.required],
      email: [this.partnerSelect.email, Validators.required],
      role: [this.partnerSelect.role, Validators.required],
      code: [this.partnerSelect.code, Validators.required],
      socio: [this.partnerSelect.socio, Validators.required],
      carnet: [this.partnerSelect.pathCard, Validators.required],
      contract: [this.partnerSelect.pathContract, Validators.required],
      terms: [this.partnerSelect.terms, Validators.required]
    });
  }

  startEdit() {
    this.isEdit = true;
  }

  cancelEdit(){
    this.isEdit = false;
  }

  editPartnerSelected(){
    this.db.doc('users/' + this.partnerSelect.id).update(this.formPartnerEdit.value).then(() => {
      this.msj.messageCorrect('Usuario Editado', 'El usuario' + this.partnerSelect.name + 'ha sido editado exitosamente!');
    }).catch((err) => {
      this.msj.messageError('Error en edición', 'Ha ocurrido un error');
      console.log(err);
    });
  }

  uploadCarnet(event){
    let file = event.target.files[0];
    let path = 'carnet/'+ this.partnerSelect.id;
    const ref = this.storage.ref(path);
    const upload = this.storage.upload(path, file);

    upload.snapshotChanges().pipe(
      finalize(
        () => ref.getDownloadURL().subscribe(url => {
          this.db.doc(this.dbUsers+'/'+this.partnerSelect.id).update({
            pathCard: url
          })
          this.msj.messageCorrect('Carnet subido', 'Se ha subido la foto de carnet exitosamente!');
        })
      )
    ).subscribe();
    // const task = ref.put(file);
    // task.then((obj) => {
    //   ref.getDownloadURL().subscribe(url => {

    //   })
    //   this.msj.messageCorrect('Carnet subido', 'Se ha subido la foto de carnet exitosamente!');
    // }).catch((err) => {
    //   console.log(err);
    //   this.msj.messageError('Error al subir documento', 'Ha ocurrido un error')
    // });
  }

  uploadContract(event){
    let file = event.target.files[0];
    let path = 'contract/'+ this.partnerSelect.id;
    const ref = this.storage.ref(path);
    const upload = this.storage.upload(path, file);

    upload.snapshotChanges().pipe(
      finalize(
        () => ref.getDownloadURL().subscribe(url => {
          this.db.doc(this.dbUsers+'/'+this.partnerSelect.id).update({
            pathContract: url
          })
          this.msj.messageCorrect('Carnet subido', 'Se ha subido la foto de carnet exitosamente!');
        })
      )
    ).subscribe();
  }

  // Deploy Prescription

  private savePrescription(prescription: any) {
    this.db.collection(`${ this.db_prescription}`).add( prescription );
  }

  loadPrescription() {
    for (const item of this.prescription) {
      item.isUploading = true;
      if (item.progress >= 100) {
        continue;
      }

      const file = item.file;
      const filePath = `${this.db_prescription}/${ item.nameFile }`;
      const fileRef = this.storage.ref( filePath );
      const uploadTask = this.storage.upload(filePath, file);

      uploadTask.percentageChanges().subscribe(res => item.progress = res);

      uploadTask.snapshotChanges().pipe(
        finalize(
          () => fileRef.getDownloadURL().subscribe( url => {
            console.log('Receta cargada con exito!');
            item.url = url;
            item.isUploading = false;
            this.savePrescription({
              id: this.partnerSelect.id,
              name: item.nameFile,
              url: item.url,
              initPrescription: this.initPrescription,
              endPrescription: this.endPrescription
            });
          })
        )
      ).subscribe();
    }
  }

  cleanPrescription() {
    this.prescription = [];
  }

  readPrescriptionsPartnerSelected(){
    this.db.collection(this.db_prescription).get().subscribe(res => {
      this.prescriptionsPartnerSelected.length = 0;
      res.docs.forEach(item => {
        if(item.id === this.partnerSelect.id){
          this.prescriptionsPartnerSelected.push(item.data());
        }
      })
    })
  }
  // onSubmit(f: NgForm) {
    // let value = f.value.adm;
    // let addAdmin = this.fns.httpsCallable('addAdmin');
    // addAdmin({email: value}).subscribe(res => {
    //   console.log(res);
    // });
  // }

  // onSubmit(f: NgForm) {
    // let value = f.value.ptnr;
    // let addPartner= this.fns.httpsCallable('addPartner');
    // addAdmin({email: value}).subscribe(res => {
    //   console.log(res);
    // });
  // }

}
