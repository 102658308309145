import { Directive, EventEmitter, ElementRef, HostListener, Input, Output } from '@angular/core';
import { Prescription } from '../models/prescription.models';

@Directive({
  selector: '[appNgDropPrescription]'
})
export class NgDropPrescriptionDirective {

  @Input() prescription: Prescription[] = [];
  @Output() mouseOn: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  @HostListener('dragover', ['$event'])
  public onDragEnter(event: any) {
    this.mouseOn.emit(true);
    this._preventAndStop(event);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any) {
    this.mouseOn.emit(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any) {
    
    const transfer = this._getTransfer(event);
    if ( !transfer ){
      return;
    }
    
    this._extractPrescription(transfer.files);
    this._preventAndStop(event);
    this.mouseOn.emit(false);
  }

  private _getTransfer(event: any){
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _extractPrescription( list: FileList ){

    for (const prop in Object.getOwnPropertyNames( list )){
      const fileTemp = list[prop];

      if (this._canBeUploaded(fileTemp)) {
        const newFile = new Prescription(fileTemp);
        this.prescription.push( newFile );
      }
    }
    console.log(this.prescription);
  }

  //  Validate
  private _preventAndStop( event ) {
    event.preventDefault();
    event.stopPropagation();
  }

  private _prescriptionDropped( namePrescription: string): boolean {
    for ( const presc of this.prescription) {
      if (presc.nameFile === namePrescription) {
        console.log('El archivo ' + namePrescription + ' ya esta agregado');
        return true;
      }
    }
    return false;
  }

  private _isImage( typeFile: string): boolean {
    //return (typeFile === '' || typeFile === undefined)? false : typeFile.startsWith('image');
    return (typeFile === '' || typeFile === undefined)? false : true;
  }

  private _canBeUploaded (prescription: File): boolean {
    if( !this._prescriptionDropped(prescription.name) && this._isImage( prescription.type)) {
      return true;
    }

    return false;
  }
}
