import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Partner } from 'src/app/models/usuario.models';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.css']
})
export class ProfileUserComponent implements OnInit {


  private dbUsers = 'users';
  namePage = 'Perfil del usuario';
  userPartner: Partner;

  // status activity
  selectProfileUser = false;
  selectPresentation = false;
  selectMultimedia = false;

  constructor(private ls: LoginService,
              private auth: AngularFireAuth,
              private db: AngularFirestore) {
  }

  ngOnInit() {
    this.ls.stateLog.emit( true );
    this.getDataUser(this.getUser());
  }

  private getUser(): string {
    return this.auth.auth.currentUser.uid;
  }

  private getDataUser(uid: string) {
    this.db.collection(this.dbUsers).doc(uid).get().subscribe(data => {
      this.userPartner = data.data() as Partner;
      this.userPartner.id = data.id;
      this.userPartner.ref = data.ref;
      this.userPartner.visible = true;
    });
  }

  selectActivity(t: number) {
    this.selActivity(t);
  }

  private selActivity(t: number) {
    switch (t) {
      case 1:
          this.selectProfileUser = true;
          this.selectPresentation = false;
          this.selectMultimedia = false;
          break;
      case 2:
          this.selectProfileUser = false;
          this.selectPresentation = true;
          this.selectMultimedia = false;
          break;
      case 3:
          this.selectProfileUser = false;
          this.selectPresentation = false;
          this.selectMultimedia = true;
          break;
      default:
          console.log('this option doesn\'t exists!');
    }
  }
}
