import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  namePage = 'Contacto';
  formEmail: FormGroup;
  private dbEmail = 'emails';
  siteKey: string;

  constructor(private formBuilder: FormBuilder,
              private db: AngularFirestore,
              private msj: MessageService) { 
                this.createFormEmail();
                this.siteKey = '6Lf4JyMbAAAAADUY2zBqoqkhC_ufhXlPxGXC98NS';
              }

  ngOnInit() {
  }

  createFormEmail() {
    this.formEmail = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      text: ['', Validators.required],
      check: ['', Validators.required]
    })
  }

  onSubmit(f: NgForm){
    if (this.formEmail.invalid ) {
      return Object.values( this.formEmail.controls ).forEach( ctrl => {
       ctrl.markAllAsTouched();
     });
   } else {
     this.setValuesEmail(f);
     this.msj.messageCorrect('Correo Enviado!', 'El correo ha sido enviado con exito!, espera a tener noticias de parte de nuestro equipo.');
   }
  }

  private async setValuesEmail(f: NgForm) {
    const idEmail = this.generateIdEmail();
    return await this.db.collection(this.dbEmail).doc(idEmail).set({
      name: f.value.name,
      email: f.value.email,
      phone: f.value.phone,
      text: f.value.text,
      check: f.value.check
    });
  }

  private generateIdEmail(): string {
    const chars = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'];
    const min = 8;
    const max = 10;
    const limit = Math.floor(Math.random() * (max - min + 1) + min);
    // tslint:disable-next-line: no-bitwise
    const randomCode = [...Array(limit)].map(i => chars[Math.random() * chars.length | 0]).join('');
    return randomCode;
  }

}
