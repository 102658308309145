// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDLWQ7XRWSgzhP0VMlGTJBEOsGGUKDjZ30',
    authDomain: 'c-crea-ac053.firebaseapp.com',
    databaseURL: 'https://c-crea-ac053.firebaseio.com',
    projectId: 'c-crea-ac053',
    storageBucket: 'c-crea-ac053.appspot.com',
    messagingSenderId: '148353929124',
    appId: '1:148353929124:web:cd7e50128481edadc6bb31'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
