import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  namePage = 'Aspectos Legales';
  urlRules1 = 'https://drive.google.com/file/d/1-dh87Wl4cSN8_Nel_d-QCcbATG59AX3z/view?usp=sharing';
  urlRules2 = 'https://drive.google.com/file/d/1vEFIS7vNGgjMINbjo3FHlZqZjX1OhR7x/view?usp=sharing';
  urlRules3 = 'https://drive.google.com/file/d/1UkXeiwerFjAjmspzOie0NmTFPPYCmFuX/view?usp=sharing';

  constructor() {
   }

  ngOnInit() {
  }
}
