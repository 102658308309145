import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  messageError(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'error'
    });
  }

  messageWarning(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }

  messageCorrect(titulo: string, mensaje: string) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'success'
    });
  }

  messageInfo(titulo: string, mensaje: string) {
    Swal.fire({
      allowOutsideClick: false,
      title: titulo,
      text: mensaje,
      icon: 'info'
    });
  }
}
